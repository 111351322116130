import React from "react"

import "./blog.scss"

const tangledDogs = `https://s3-us-west-1.amazonaws.com/laniabels.com/photos/tangled_dogs.jpg`
class BlogContainer extends React.Component {
  renderBlogPreview = () => {
    return (
      <div className="blog-preview">
        <div className="preview-img"></div>
        <div className="preview-text">
          <h4>Blog Title</h4>
          <p>Here is some initial blog text...</p>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="blog-container">

        {/* <div className="blog-preview row">
          <div className="media-preview column">
            <div className="photo"><img src={tangledDogs} /></div>
          </div>
          <div className="text-preview column">
            <h4>Snow Dogs</h4>
            <p>Coming soon, a tale of four dogs in the snow...</p>
          </div>
        </div> */}


        <div className="flip-card">
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <img src={tangledDogs} alt="Snow Dogs" />
            </div>
            <div className="flip-card-back">
            <h4>Snow Dogs</h4>
            <p>Coming soon, a tale of four dogs in the snow...</p>
            </div>
          </div>
        </div>

        
      </div>
    )
  }
}

export default BlogContainer
