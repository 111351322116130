import React from "react"
import { hot } from "react-hot-loader"

import classnames from "classnames"

import BlogContainer from "./blog/blogContainer.js"

import { imageNames, imageUrl, laniPortrait, github, linkedIn } from "../constants/constants.js"


import "./App.scss"
const fondLogo = "https://s3-us-west-1.amazonaws.com/laniabels.com/photos/Fond.png";
const truckerLineLogo = "https://s3-us-west-1.amazonaws.com/laniabels.com/photos/truckerline.png";
const dfLogo = "https://s3-us-west-1.amazonaws.com/laniabels.com/photos/df.png";

class App extends React.Component {
  constructor(props) {
    super(props)
    const initialImage = imageUrl + `finn_wine`
    
    this.state = {
      pathName: "about",
      bgImgString: initialImage,
      bgImgIndex: 0
    }
  }

  onNavLinkClicked = (pathName) => {
    this.setState({ pathName })
  }

  onContactCliked = () => {
    window.scrollTo(0, window.document.body.scrollHeight)
  }

  onSliderChange = () => {
    this.setState({ value, dragging: !this.state.dragging })
  }

  renderMainText = () => {
    return (
      <div className="about-me-text">
        <div className="portrait">
          <img src={laniPortrait} alt="Lani with her puppy Finn" />
        </div>

        <div className="text">
          <p>
            Welcome. You've found your way here, so I'd like to tell you a little bit about myself.
          </p>
        
          <p>
            In my free time, I prefer spending time off the computer and with my pack of dogs.
            Currently we are working on pulling, after a somewhat successful attempt in the snow
            this winter.  When not spending time with the pack, I pursue a variety of hobbies, with the
            most recent projects teaching me soldering, masonry, carpentry, and gardening
            (plant survival rate is now up to 60%). My saltwater aquarium has helped me get over my fear of both
              ocean creatures and "science".
          </p>
            
          <p>
            Professionally I have been working as a software engineer for the past five years, but prior to
            that I was a mathematics tutor for middle school through college.  Most recently I led a team
            of five engineers, helping optimize the team velocity by breaking down tasks, increasing
            documentation, and having increased team communication allowing for tasks to be completed more efficiently.
          </p>
        </div>

      </div>
    )
  }

  renderAboutMeView = () => {
    return (
      <div className="about-me">
        <div className="hero-image">
          <div className="hero-text">
            <h1>Lani Abels</h1>
            <h3>Dog Lover, Programmer, Expert Hobbyist</h3>
            <button className="contact" onClick={ this.onContactCliked }>Let's Talk</button>
          </div>
        </div>
        { this.renderMainText() }
      </div>
    )
  }

  renderContactMeView = () => {
    return (
      <div className="contact-view">
        <h2>Contact me</h2>
        <a className="email-link" href="mailto:thelabelsabels@gmail.com?subject=Inquiry">
          <p>thelabelsabels@gmail.com</p>
        </a>
        
        <div className="social-icons">
          <span className="linkedin">
            <a href="https://www.linkedin.com/in/alabels/">
              <img src={linkedIn} alt="LinkedIn icon" />
            </a>
          </span>
          <span>
            <a href="https://github.com/alabels9/">
              <img src={github} alt="GitHub icon" />
            </a>
          </span>
        </div>
      </div>
    )
  }

  renderWorkView = () => {
    return (
      <div className="work-view">
        <h2>Here is some stuff I can help you with</h2>
        <div className="section">
          <div className="item odd">
            <h3>Develop your product idea and create prototypes</h3>
            <div>Discuss the product, what MVPs a prototype needs, continue work on an already started product.</div>
          </div>

          <div className="item">
            <h3>Create or update a website customized to your needs</h3>
            <div>Update the style, colors, flow, or start from scratch.</div>
          </div>

          <div className="item odd">
            <h3>QA testing to ensure good UX and working as expected software</h3>
            <div>Minor bugs and inconsistencies. Ensure the flows are working as expected. Intuitive for users.</div>
          </div>

          <div className="item">
            <h3>Facilitate meetings, sprint planning, task breakdowns, retrospectives</h3>
            <div>
              "I hate meetings!" a complaint I've heard more than once from many an employee, particularly engineers.
              What they hate is the meandering pace and lack of progress that is made. No one wants to be in a meeting any longer
              than they have to, so the key is to stay on topic and intervene when conversations become tangential.
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderBlogView = () => {
    return <BlogContainer />
  }

  renderImageTiles = () => {
    const prefix = 'https://s3-us-west-1.amazonaws.com/laniabels.com/photos/'
    const imageNames = [
      'FinnKawawa.JPG',
      'CinderPool.jpg',
      'FinnGrass.jpg',
      'tangled_dogs.jpg',
      'FinnWine.jpg',
      'LaniFinnWine.JPG',
      'RiverSteps.jpg',
      'chappie.jpg',
      // 'leftySmile.png',
      'laniFinn.png',
      // 'leftyRiver.png',
      'packOnHill.png',
    ]
    
    return imageNames.map(img => {
      const name = prefix + img
      return (
        <div className="tile">
          <img className="image-tile" key={img} src={name} alt={name} />
        </div>
      )
    })
  }

  renderRevamp = () => {
    return (
      <div>
        <div className="landing background-img">
          <div className="welcome-container">
            <p className="tagline">Software Engineer, Dog Lover, Expert Hobbyist</p>
            <h1>Lani Abels</h1>
            <button className="contact">
              <a className="email-link" href="mailto:thelabelsabels@gmail.com?subject=Inquiry">
                Let's Talk
              </a>
            </button>
          </div>
        </div>

        <div className="professional">
          <div className="title-container">
            <h2>Professional Work</h2>
          </div>
          <p>Professionally I have been working as a software engineer for the past five years, but prior to that I was a mathematics tutor for middle school through college. Most recently I led a team of five engineers, helping optimize the team velocity by breaking down tasks, increasing documentation, and having increased team communication allowing for tasks to be completed more efficiently.</p>
          
          <div className="row">
            <h3 className="text-center">Previous Companies</h3>
          </div>
          <div className="previous-work">
            <div className="row">
              <div className="bold">Fond</div>
              <div className="job-description">
                Working at Fond I was able to develop my skills more fully. I was given the opportunity to work on my backend skills,
                product design, and managing a team. As a Senior Software Engineer I enjoyed mentoring my teammates, and pair programming
                to share skills and knowledge. I learned the value of collaboration and a creative atmosphere.
              </div>
            </div>

            <div className="row">
              <div className="bold">TruckerLine</div>
              <div className="job-description">
                Trucker Line was my induction into the world of start ups. I learned what it means to feel ownership and pride in my work,
                and it gave me the freedom to discover how to work my own style with the standards of the time. My team helped me learn to make
                my own choices, and how to work smartly to optimize my time.
              </div>
            </div>

            <div className="row">
              <div className="bold">Digital Foundry</div>
              <div className="job-description">
                The foundation of my learning, Digital Foundry was the base of my education and future career aspirations.
                I was fortunate to work on a variety of projects for different clients, which allowed me to learn a variety of
                languages and frameworks, as well as specialized skills, such as accessibility, and to try my hand at making
                native apps for iOS and Andoid.
              </div>
            </div>
          </div>
        </div>

        <div className="personal">
          <div className="title-container">
            <h2>My Life</h2>
          </div>
          <p>
            In my free time, I prefer spending time outside and with my pack of dogs.
            When not spending time with the pack, I pursue a variety of hobbies. Currently I am trying my hand at leatherwork. Recently I've enjoyed carpentry and house remodeling projects.
            My saltwater aquarium has helped me get over my fear of both ocean creatures and "science".
          </p>
          <div className="image-tile-container">
            { this.renderImageTiles() }
          </div>
        </div>

        <div className="footer">
          <h3>Get in touch and lets create together.</h3>
          { this.renderContactMeView() }
        </div>
      </div>
    )
  }

  /**
   * Values
   * 
   */

  renderPageContent = () => {
    const { pathName } = this.state
    switch(pathName) {
      case "work":
        return this.renderWorkView()
      case "about":
        return this.renderRevamp()
      case "blog":
        return this.renderBlogView()
      default:
        return this.renderRevamp()
    }
  }

  renderNav = () => {
    return (
      <div
        className={classnames("nav-container", { 'static': this.state.isNavbarStatic })}
        ref="navContainer"
      >
        <nav>
          <ul className="">
            <li
              className={classnames("", { 'active': this.state.pathName === "about" })}
              onClick={ () => this.onNavLinkClicked("about") }
            >
              About Me
            </li>
            <li
              className={classnames("", { 'active': this.state.pathName === "work" })}
              onClick={ () => this.onNavLinkClicked("work") }
            >
              Work
            </li>
            <li
              className={classnames("", { 'active': this.state.pathName === "blog" })}
              onClick={ () => this.onNavLinkClicked("blog") }
            >
              Blog
            </li>
          </ul>
        </nav>
      </div>
    )
  }

  render() {
    return (
      <div className="app">
        { this.renderPageContent() }
      </div>
    )
  }
}

export default hot(module)(App)
